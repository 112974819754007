import { createRouter, createWebHistory } from "vue-router";
import { useSeatStore } from "@/stores/seat";
import { useEventStore } from "@/stores/event";
import { useCurrentStore } from "@/stores/current";
import { useWebRTCStore } from "@/stores/webrtc";
import adminRoutes from "./admin";
import BaseView from "@/views/BaseView.vue";
import { useAuthStore } from "@/stores/admin/auth";

const baseRoutes = [
    {
        path: "/",
        redirect: (to) => {
            if (to.query.e)
                return { name: "home", params: { eventSlug: to.query.e } };
            return { name: "website" };
        },
    },
    {
        name: "website",
        path: "/website",
        beforeEnter() {
            location.href = "https://soonia.it";
        },
    },
    {
        path: "/:eventSlug",
        component: BaseView,
        children: [
            {
                path: "",
                component: () => import("@/views/ChannelsView.vue"),
                name: "home",
            },
            {
                path: "seat",
                component: () => import("@/views/SeatView.vue"),
                name: "seat",
            },
            {
                path: "listen",
                component: () => import("@/views/ListenView.vue"),
                name: "listen",
            },
            {
                path: "allPolls",
                component: () => import("@/views/PollsView.vue"),
                name: "polls",
                meta: { pollResults: true },
            },
            {
                path: "pollResults",
                component: () => import("@/views/PollResultsView.vue"),
                name: "pollResults",
                meta: { pollResults: true },
            },
        ],
    },
];

const adminPrefix = import.meta.env.VITE_ADMIN_PREFIX || "admin";
const routes =
    window.location.host.split(".")[0] === adminPrefix
        ? adminRoutes
        : baseRoutes;

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresAuth && !useAuthStore().getUser) {
        return next({ name: "login", query: { redirect: to.fullPath } });
    }
    if (to.meta.requiresAuth && !useAuthStore().getUser.emailVerified) {
        return next({ name: "verify-email" });
    }
    if (to.query.sr) useSeatStore().setSeatRow(to.query.sr);
    if (to.query.sn) useSeatStore().setSeatNumber(to.query.sn);
    if (to.query.sr || to.query.sn) useSeatStore().confirm();
    if (from.name === "listen" && useCurrentStore().channel) {
        useCurrentStore().setChannel(null);
        useWebRTCStore().stopStream();
        const audio = document.getElementById("remoteAudio");
        if (audio && !audio.paused && audio.srcObject) audio.pause();
    }
    if (to.name === "listen" && !useCurrentStore().channel) {
        return next({
            name: "home",
            params: { eventSlug: to?.params?.eventSlug },
        });
    }
    if (to.meta.admin) {
        useCurrentStore().setAdmin(true);
    }
    if (to.meta.pollResults) {
        useCurrentStore().setPollResults(true);
    }
    if (
        useEventStore().getEvent?.seatNumber &&
        to.name !== "seat" &&
        !to.meta.admin &&
        !useSeatStore().seatNumber
    ) {
        return next({
            name: "seat",
            params: { eventSlug: to.params.eventSlug },
        });
    }
    return next();
});

router.afterEach((to) => {
    useEventStore().setSlug(to.params.eventSlug);
});

export default router;
