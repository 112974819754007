import { defineStore } from "pinia";
import { toast } from "vue3-toastify";
import { useI18nStore } from "@/stores/i18n";

export const useNotificationsStore = defineStore("notifications", {
    actions: {
        success(key) {
            toast.success(useI18nStore().t(key));
        },
        error(key) {
            toast.error(useI18nStore().t(key));
        },
        warning(key) {
            toast.warning(useI18nStore().t(key));
        },
    },
});
