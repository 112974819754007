import { defineStore } from "pinia";
import pollResults from "../services/pollResults";
import { useEventStore } from "@/stores/event";
import polls from "@/services/admin/polls";

export const usePollResultsStore = defineStore("pollResults", {
    state: () => ({ pollId: null, report: null, greenBg: false }),
    actions: {
        setPollId(pollId) {
            this.pollId = pollId;
        },
        setReport(report) {
            this.report = report;
        },
        async getPollResults(slug) {
            try {
                const r = await pollResults.getPollResults(slug);
                this.report = r.data;
            } catch (e) {
                const message = e?.response?.data?.message;
                if (message === "no-active-poll") this.report = null;
                else throw e;
            }
        },
        async fetchGreenBg(slug) {
            try {
                const r = await polls.getGreenBg(slug);
                this.setGreenBg(r.data.enabled);
            } catch (error) {
                console.log(error);
            }
        },
        setGreenBg(enabled) {
            this.greenBg = enabled;
            document.body.style.backgroundColor = enabled ? "#00ff00" : "white";
        },
    },
    getters: {
        getPoll: (state) => state.report,
        getQuestion: (state) => {
            const language = useEventStore().getEvent?.defaultLanguage;
            if (!language || !state.report) return "";
            return state.report.question[language];
        },
        getAnswers: (state) => {
            const language = useEventStore().getEvent?.defaultLanguage;
            if (!language || !state.report) return [];
            return state.report.answers.map((a) => {
                if (typeof a.text === "object") return a.text[language];
                return a.text; // feedback
            });
        },
        getAnswerVotesPercent: (state) => {
            const answers = state.report.answers || [];
            return (answerId) =>
                roundTo(
                    (answers.find((x) => x.id === answerId).numVotes /
                        answers?.reduce(
                            (acc, current) =>
                                acc + Number.parseInt(current.numVotes),
                            0
                        )) *
                        100,
                    2
                );
        },
        getAnswersPercent: (state) => {
            if (!state.report?.answers) return [];
            const result = [];
            for (const answer of state.report.answers) {
                result.push(state.getAnswerVotesPercent(answer.id));
            }
            return result;
        },
        getGreenBg: (state) => state.greenBg,
    },
});

function roundTo(n, digits) {
    if (digits === undefined) {
        digits = 0;
    }

    const multiplier = Math.pow(10, digits);
    n = parseFloat((n * multiplier).toFixed(11));
    const test = Math.round(n) / multiplier;
    return +test.toFixed(digits);
}
