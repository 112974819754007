<script setup>
import { XMarkIcon } from "@heroicons/vue/24/solid";
import PollOption from "@cmp/PollOption.vue";
import { useCurrentStore } from "@/stores/current";
import { usePollStore } from "@/stores/poll";
import { computed, onMounted, ref, watch } from "vue";
import EditorJS from "@editorjs/editorjs";
import Embed from "@editorjs/embed";
import SimpleImage from "@editorjs/simple-image";

const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
    poll: {
        type: Object,
        required: true,
    },
});

const language = computed(() => useCurrentStore().language);
const answerId = computed(() => usePollStore().getSelectedAnswerId);

function closePopup() {
    usePollStore().setPoll(null);
}

function sendAnswer() {
    usePollStore().sendAnswer();
    usePollStore().setPoll(null);
}

const submitText = computed(() => {
    const textLanguage = language.value ?? defaultLanguage.value;
    if (textLanguage === "it") return "Invia";
    return "Submit";
});

const defaultLanguage = computed(() => {
    return props.poll?.defaultLanguage ?? props.event.defaultLanguage;
});

const question = computed(() => {
    if (!props.poll?.question) return "";
    return (
        props.poll.question[language.value ?? defaultLanguage.value] ??
        props.poll.question[defaultLanguage.value]
    );
});

const answers = computed(() => {
    if (!props.poll?.answers) return [];
    return props.poll.answers.map((answer) => {
        return {
            id: answer.id,
            text:
                answer.text[language.value ?? defaultLanguage.value] ??
                answer.text[defaultLanguage.value],
        };
    });
});

const editor = ref();

onMounted(() => {
    if (props.poll.type === "message") createEditor();
});

watch(
    () => props.poll,
    () => {
        // timeout to wait for the editor to be created
        setTimeout(() => {
            if (props.poll.type === "message") createEditor();
        }, 200);
    }
);

function createEditor() {
    if (editor.value) editor.value.destroy();
    if (props.poll.type !== "message") return;
    editor.value = new EditorJS({
        holder: document.getElementById("editor"),
        minHeight: 50,
        tools: {
            embed: Embed,
            image: SimpleImage,
        },
        readOnly: true,
        data: question.value,
    });
}
</script>

<template>
    <div
        class="fixed inset-0 bg-black bg-opacity-60 z-[60] py-10 px-4 cursor-pointer flex justify-center items-center"
        @click="closePopup"
    >
        <div
            class="bg-white mx-auto w-full max-w-lg rounded-2xl p-5 cursor-default max-h-full overflow-auto relative"
            @click.stop
        >
            <div class="absolute top-8 right-8 z-10">
                <button class="w-[20px] aspect-square" @click="closePopup">
                    <XMarkIcon />
                </button>
            </div>
            <div id="editor" v-if="poll.type === 'message'" />
            <div class="flex flex-row mt-5 items-center mb-5" v-else>
                <h1
                    class="ml-2 font-semibold text-xl pr-10 break-words w-full overflow-auto"
                >
                    {{ question }}
                </h1>
            </div>
            <div
                v-if="poll.type === 'singleChoice'"
                class="flex flex-col gap-2"
            >
                <PollOption
                    v-for="answer in answers"
                    :key="answer.id"
                    :selected="answer.id === answerId"
                    :text="answer.text"
                    @click="usePollStore().setAnswer(answer.id)"
                />
            </div>
            <div
                v-else-if="poll.type === 'feedback'"
                class="w-full flex justify-center"
            >
                <button
                    v-for="n in 5"
                    :key="n"
                    @click="usePollStore().setAnswer(n)"
                >
                    <svg
                        width="50"
                        height="50"
                        :fill="n <= answerId ? '#EBE100' : 'none'"
                        viewBox="0 0 24 24"
                    >
                        <path
                            stroke="#EBE100"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="1.5"
                            d="M12 4.75L13.75 10.25H19.25L14.75 13.75L16.25 19.25L12 15.75L7.75 19.25L9.25 13.75L4.75 10.25H10.25L12 4.75Z"
                        />
                    </svg>
                </button>
            </div>
            <button
                :disabled="!answerId"
                class="w-full p-4 bg-black rounded-2xl mt-5 text-white font-semibold disabled:bg-gray-500"
                @click="sendAnswer"
                v-if="poll.type !== 'message'"
            >
                {{ submitText }}
            </button>
        </div>
    </div>
</template>
