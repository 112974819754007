import adminIT from "./it.json";
import adminEN from "./en.json";
import userDE from "./user-side/de.json";
import userEN from "./user-side/en.json";
import userES from "./user-side/es.json";
import userFR from "./user-side/fr.json";
import userIT from "./user-side/it.json";
import userRO from "./user-side/ro.json";
import userRU from "./user-side/ru.json";

export const defaultLocale = "en";

const userSideLanguages = {
    de: userDE,
    en: userEN,
    es: userES,
    fr: userFR,
    it: userIT,
    ro: userRO,
    ru: userRU,
};

export const languages = () => {
    const adminPrefix = import.meta.env.VITE_ADMIN_PREFIX || "admin";
    if (window.location.host.split(".")[0] === adminPrefix) {
        return {
            en: adminEN,
            it: adminIT,
        };
    }
    return userSideLanguages;
};

export const locales = Object.keys(languages());
export const userSideLocales = Object.keys(userSideLanguages);
