<template>
    <div
        class="h-full flex flex-col justify-center items-center z-[100] absolute inset-0 bg-black bg-opacity-30"
        @click="tapTapped"
    >
        <button
            class="bg-black px-6 py-3 text-white rounded-2xl !z-30 !opacity-100 !bg-opacity-100"
            @click="tapTapped"
        >
            {{ tapToStartText }}
        </button>
        <div
            :class="{
                tapStartInactive: !btnOverlay,
                tapStartActive: btnOverlay,
            }"
            class="transition-all duration-1000 ease-in-out"
        >
            .
        </div>
    </div>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useCurrentStore } from "@/stores/current";
import { useEventStore } from "@/stores/event";

const btnOverlay = ref(false);

const tapToStartText = computed(() => {
    return useEventStore().getEvent?.tapToStartText ?? "Tap to start";
});

function toggleOverlay() {
    btnOverlay.value = !btnOverlay.value;
    setTimeout(toggleOverlay, 1000);
}

onMounted(() => {
    toggleOverlay();
});

function tapTapped() {
    useCurrentStore().setFirstInteraction(true);
}
</script>

<style scoped>
.tapStartActive {
    @apply absolute opacity-20 bg-black rounded-2xl px-28 py-6 z-20;
}

.tapStartInactive {
    @apply absolute opacity-0 bg-black rounded-2xl px-0 py-0 z-20;
}
</style>
