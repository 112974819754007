<script setup>
import { useLightsStore } from "@/stores/lights";
import { useCurrentStore } from "@/stores/current";
import { computed } from "vue";
import FooterLogo from "@cmp/FooterLogo.vue";
import { useEventStore } from "@/stores/event";

const color = computed(() => useLightsStore().getColor);
const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
});

const language = computed(() => useCurrentStore().language);
const show = computed(() => useLightsStore().getShow);
const sooniaLights = computed(() => {
    if (useEventStore().isClosed) return false;
    return props.event.sooniaLightsScreensaver || show.value;
});

const eventCopy = computed(() => {
    if (!props.event?.copy) return "";
    const keys = Object.keys(props.event.copy);
    if (keys.length === 0) return "";
    if (keys.length === 1) return props.event.copy[keys[0]];
    if (keys.includes(language.value)) return props.event.copy[language.value];
    return props.event.copy[keys[0]];
});

const loadUrl = import.meta.env.VITE_S3_BASE_URL;

const fullWidth = computed(() => !!props.event.screensaverLogo);

const imageUrl = computed(() => {
    const name = props.event.screensaverLogo || props.event.navbarLogo;
    return loadUrl + name;
});
</script>

<template>
    <div
        :style="{ backgroundColor: sooniaLights ? color : '#FFFFFF' }"
        class="fixed h-full w-full flex flex-col justify-center items-center z-50 inset-0"
    >
        <div class="w-full h-full flex flex-row items-center">
            <img
                class="mx-auto max-h-full"
                :class="{
                    hidden: show,
                    'w-full max-w-lg px-4': !fullWidth,
                    'max-w-full': fullWidth,
                }"
                :src="imageUrl"
                alt="screensaver logo"
            />
            <p class="py-5 text-lg" v-if="eventCopy" :class="{ hidden: show }">
                {{ eventCopy }}
            </p>
        </div>
        <FooterLogo
            v-if="event.screensaverSooniaFooter"
            :class="{ hidden: show }"
            :can-be-gray="false"
            force-theme="light"
        />
    </div>
</template>
