<script setup>
import FooterLogo from "@/components/FooterLogo.vue";
import TheSplash from "@/components/TheSplash.vue";
import TheNav from "@/components/TheNav.vue";
import { computed, onMounted, ref, watch } from "vue";
import TheScreensaver from "@/components/TheScreensaver.vue";
import PollPopup from "@/components/PollPopup.vue";
import { useCurrentStore } from "@/stores/current";
import { useLightsStore } from "@/stores/lights";
import { useEventStore } from "@/stores/event";
import { usePollStore } from "@/stores/poll";
import TapStart from "@/components/TapStart.vue";
import WarningPopup from "@cmp/WarningPopup.vue";
import { useSocketStore } from "@/stores/socketio";
import { usePollResultsStore } from "@/stores/pollResults";

const splashDuration = import.meta.env.VITE_SPLASH_DURATION ?? 5000;
const slug = computed(() => useEventStore().getSlug);
const event = computed(() => useEventStore().getEvent);
const poll = computed(() => usePollStore().getPoll);
const showTapToStart = computed(() => useCurrentStore().showTapToStart);
const firstInteraction = computed(() => useCurrentStore().getFirstInteraction);
const show = computed(() => useLightsStore().getShow);
const showScreensaver = computed(() => useCurrentStore().showScreensaver);
const showPopups = computed(
    () => !useCurrentStore().getIsAdmin && !useCurrentStore().getIsPollResults
);

watch(
    () => poll.value,
    (poll) => {
        if (!poll || !firstInteraction.value) return;
        document.querySelector("#pingAudio").currentTime = 0;
        document.querySelector("#pingAudio").play();
    }
);

const splash = ref(true);
onMounted(() => {
    try {
        useEventStore().getEventInfo(slug.value);
    } catch (e) {
        console.error(e);
    }
    setTimeout(async () => {
        await useCurrentStore().restartScreensaverTimer();

        if (!event.value?.languages) {
            await useEventStore().getEventInfo(slug.value);
        }
        splash.value = false;
    }, splashDuration);
});

function interactionHandler() {
    useCurrentStore().setFirstInteraction(true);
    useCurrentStore().refreshNoSleep();
    if (show.value) return;
    useCurrentStore().restartScreensaverTimer();
}
</script>

<template>
    <div
        class="absolute w-full h-full flex flex-col items-center inset-0"
        @click="interactionHandler"
        @keydown="interactionHandler"
        @keyup="interactionHandler"
    >
        <Transition>
            <TheSplash v-if="splash" :event="event" />
        </Transition>
        <template v-if="event">
            <TapStart v-if="showTapToStart && !splash" />
            <transition>
                <PollPopup
                    v-if="showPopups && !splash && poll"
                    :event="event"
                    :poll="poll"
                />
            </transition>
            <transition>
                <WarningPopup
                    class="z-[55]"
                    v-if="
                        showPopups &&
                        !splash &&
                        useSocketStore().getMaxUsersReached
                    "
                    type="warning"
                    :title="$t('maxUsersReached.title')"
                    :text="$t('maxUsersReached.text')"
                />
            </transition>
            <audio id="pingAudio" class="hidden" src="/ping.mp3" />
            <audio
                id="remoteAudio"
                :title="`Soonia - ${slug}`"
                class="hidden"
                controls=""
            ></audio>
            <transition>
                <TheScreensaver v-show="showScreensaver" :event="event" />
            </transition>
            <TheNav :event="event" v-if="!usePollResultsStore().getGreenBg" />
            <div class="grow w-full">
                <router-view :class="{ hidden: splash }" />
            </div>
            <FooterLogo v-if="!usePollResultsStore().getGreenBg" />
        </template>
    </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
    transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
