import { createApp } from "vue";
import { createPinia } from "pinia";

import { createI18n } from "vue-i18n";
import { defaultLocale, languages, userSideLocales } from "@/i18n";
import App from "./App.vue";
import router from "./router";

import "./assets/main.css";
import sentry from "./services/sentry";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import Vue3Toasity from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import {
    faAdd,
    faCartShopping,
    faCircleCheck,
    faCircleQuestion,
    faCircleXmark,
    faClosedCaptioning,
    faCopy,
    faFileArrowDown,
    faGear,
    faListUl,
    faMusic,
    faPaperPlane,
    faPen,
    faPencil,
    faQuestion,
    faRightFromBracket,
    faShare,
    faStar,
    faTriangleExclamation,
    faUpRightFromSquare,
    faWrench,
    faXmark,
} from "@fortawesome/free-solid-svg-icons";
import smoothscroll from "smoothscroll-polyfill";
import { useI18nStore } from "@/stores/i18n";
import { chatwoot } from "@/services/chatwoot";

const messages = Object.assign(languages());

const app = createApp(App);
sentry.init(app, router);

app.use(createPinia());
app.use(router);
app.use(Vue3Toasity, {
    position: "bottom-center",
    newestOnTop: true,
});
app.use(chatwoot);

const i18n = createI18n({
    locale: defaultLocale,
    fallbackLocale: defaultLocale,
    messages,
});
app.use(i18n);
useI18nStore().setI18n(i18n);
useI18nStore().setUserSideLocales(userSideLocales);

library.add(
    faGear,
    faCopy,
    faCartShopping,
    faPencil,
    faStar,
    faCircleQuestion,
    faCircleCheck,
    faCircleXmark,
    faRightFromBracket,
    faShare,
    faTriangleExclamation,
    faPen,
    faXmark,
    faPaperPlane,
    faAdd,
    faWrench,
    faClosedCaptioning,
    faMusic,
    faUpRightFromSquare,
    faFileArrowDown,
    faQuestion,
    faListUl
);
app.component("font-awesome-icon", FontAwesomeIcon);

smoothscroll.polyfill();

app.mount("#app");
