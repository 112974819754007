<script setup>
import { storeToRefs } from "pinia";
import { useCurrentStore } from "@/stores/current";
import { computed } from "vue";
import { getDarkMode } from "@utils/darkmode";

const { footer } = storeToRefs(useCurrentStore());
const props = defineProps({
    forceTheme: {
        type: String,
        default: "",
    },
    canBeGray: {
        type: Boolean,
        default: true,
    },
});
const dark = computed(() => {
    if (props.forceTheme === "dark") return true;
    if (props.forceTheme === "light") return false;
    return getDarkMode();
});
const gray = computed(() => {
    if (props.canBeGray) return useCurrentStore().footerGray;
    return false;
});
</script>

<template>
    <div
        :class="{ hidden: !footer, 'from-white': !dark, 'from-black': dark }"
        class="inset-0 top-auto w-full flex justify-center justify-self-end z-0 bg-gradient-to-t"
    >
        <a
            id="footerLink"
            href="https://soonia.it/"
            target="_blank"
            class="mx-auto"
        >
            <img
                class="w-[100px] h-[100px]"
                :class="{ grayscale: gray, hidden: dark }"
                alt="logo"
                src="/images/soonia.png"
            />
            <img
                class="w-[100px] h-[100px]"
                :class="{ grayscale: gray, hidden: !dark }"
                alt="logo"
                src="/images/soonia-dark.png"
            />
        </a>
    </div>
</template>
