import { defineStore } from "pinia";

export const useLoadingStore = defineStore("loading", {
    state: () => ({ loadingQueue: 0 }),
    actions: {
        startLoading() {
            this.loadingQueue++;
        },
        stopLoading() {
            this.loadingQueue--;
        },
        resetLoading() {
            this.loadingQueue = 0;
        },
    },
    getters: {
        isLoading: (state) => state.loadingQueue > 0,
    },
});
