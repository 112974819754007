import { defineStore } from "pinia";
import { useCurrentStore } from "@/stores/current";
import { useEventStore } from "@/stores/event";
import { useSocketStore } from "@/stores/socketio";

export const usePollStore = defineStore("poll", {
    state: () => ({ poll: null, answerId: null }),
    actions: {
        async sendAnswer() {
            const id = this.poll.sessionId || this.poll.id;
            await useSocketStore().answerPoll(this.poll.id, this.answerId);
            this.answerId = null;
            localStorage.setItem("lastPollSessionVoted", id.toString());
        },
        setPoll(poll) {
            if (useCurrentStore().admin) return;
            if (!poll) {
                this.poll = poll;
                return;
            }

            const id = poll.sessionId || poll.id;
            const lastPollSessionVoted = localStorage.getItem(
                "lastPollSessionVoted"
            );
            if (
                lastPollSessionVoted &&
                lastPollSessionVoted === id.toString()
            ) {
                console.log(`${id} - already voted`);
                return;
            }
            this.setAnswer(null);
            this.poll = poll;
        },
        setAnswer(id) {
            this.answerId = id;
        },
    },
    getters: {
        getPoll: (state) => {
            if (useEventStore().isClosed) return null;
            if (useSocketStore().getMaxUsersReached) return null;
            return state.poll;
        },
        getSelectedAnswerId: (state) => state.answerId,
    },
});
