import { defineStore } from "pinia";
import { useChatWoot } from "@productdevbook/chatwoot/vue";

export const useI18nStore = defineStore("i18n", {
    state: () => ({ i18n: null, locale: null, userSideLocales: [] }),
    actions: {
        setI18n(i18n) {
            this.i18n = i18n;
            if (this.locale && this.i18n.global)
                this.i18n.global.locale = this.locale;
        },
        setLocale(locale) {
            if (!locale) return;
            this.locale = locale;
            if (this.i18n) this.i18n.global.locale = locale;
            const adminPrefix = import.meta.env.VITE_ADMIN_PREFIX || "admin";
            // without this check, a Vue warning is thrown
            if (window.location.host.split(".")[0] === adminPrefix)
                useChatWoot().setLocale(locale);
        },
        setUserSideLocales(locales) {
            this.userSideLocales = locales;
        },
    },
    getters: {
        getI18n: (state) => state.i18n,
        t: (state) => {
            return (key, locale = null) => {
                if (!state.i18n) return key;
                return state.i18n.global.t(key, locale);
            };
        },
        getDefaultLocale: (state) => state.i18n.global.fallbackLocale,
        getLocale: (state) => {
            if (state.i18n?.global) return state.i18n.global.locale;
            return state.locale;
        },
        getUserSideLocales: (state) => state.userSideLocales,
    },
});
