import { defineStore } from "pinia";
import { io } from "socket.io-client";
import { useEventStore } from "@/stores/event";
import { useSeatStore } from "@/stores/seat";
import sentry from "@/services/sentry";
import { useLightsStore } from "@/stores/lights";
import { usePollStore } from "@/stores/poll";
import { useFeedbackStore } from "@/stores/feedback";
import { useCurrentStore } from "@/stores/current";
import { useAuthStore } from "@/stores/admin/auth";
import { useSubtitlesStore } from "@/stores/subtitles";
import { usePollResultsStore } from "@/stores/pollResults";

export const useSocketStore = defineStore("socketio", {
    state: () => ({
        socket: null,
        maxUsersReached: false,
        auth: { slug: undefined, seat: undefined },
    }),
    actions: {
        initialize() {
            if (this.socket) return;
            if (!this.auth.slug) return; // slug is required
            let url = import.meta.env.VITE_API_URL;
            if (useCurrentStore().getIsPollResults) url += "/poll-results";
            else if (useCurrentStore().getIsAdmin) url += "/mgmnt";
            this.socket = io(url, {
                withCredentials: true,
                auth: (cb) => {
                    if (useCurrentStore().getIsAdmin) {
                        useAuthStore()
                            .getUserIdToken()
                            .then((token) => {
                                cb({ ...this.auth, token });
                            });
                        return;
                    }
                    cb(this.auth);
                },
            });
            attachEvents(this.socket);
        },
        setSeat(seat) {
            if (!seat) return;
            if (this.auth.seat === seat) return;
            this.auth.seat = seat;
            if (!this.socket) this.initialize();
            else this.socket.disconnect().connect();
        },
        setEvent(slug) {
            if (!slug) return;
            if (this.auth.slug === slug) return;
            this.auth.slug = slug;
            if (!this.socket) this.initialize();
            else this.socket.disconnect().connect();
        },
        answerPoll(pollId, answerId) {
            if (!this.socket) return;
            return this.socket.emit("poll", { pollId, answerId });
        },
        setLanguage(language) {
            if (!this.socket) return;
            return this.socket.emit("language", language);
        },
        sendFeedback(id, rating) {
            if (!this.socket) return;
            return this.socket.emit("feedback", id, rating);
        },
        setMaxUsersReached(value) {
            this.maxUsersReached = value;
        },
        sendLightsColor(colors) {
            if (!this.socket) return;
            return this.socket.emit("lights-color", colors);
        },
    },
    getters: {
        getMaxUsersReached: (state) => state.maxUsersReached,
        isConnected: (state) => state.socket && state.socket.connected,
        getSocket: (state) => state.socket,
    },
});

function attachEvents(socket) {
    socket.on("connect", () => {
        console.log("socket connected", socket.id);
        useSocketStore().setMaxUsersReached(false);
        if (useSeatStore().seatNumber) useSeatStore().confirm();
    });

    socket.on("disconnect", (reason, description) => {
        console.log("socket disconnected", reason, description);
    });

    socket.on("connect_error", (error) => {
        console.log("socket connect_error", error);
        sentry.captureException(error);
        if (error?.message === "max-users-reached") {
            useSocketStore().setMaxUsersReached(true);
            setTimeout(() => {
                socket.disconnect().connect();
            }, 10000);
        }
    });

    socket.on("c", (c) => {
        useLightsStore().setColor(c);
    });

    socket.on("lights", async (value) => {
        if (typeof value === "string") value = value === "on";
        await useLightsStore().setShow(value);
    });

    socket.on("randomColor", (value) => {
        useLightsStore().setRandomColor(value);
    });

    socket.on("poll", async (value) => {
        if (typeof value === "object") await usePollStore().setPoll(value);
        // ignore string values (OK, ALREADY_VOTED, NO_ACTIVE_SESSION, etc.)
    });

    socket.on("feedback", (value) => {
        useFeedbackStore().setFeedback(value);
    });

    socket.on("feedback", (titles, messages) => {
        // titles = {"IT": "Titolo", "EN": "Title"}
        // messages = {"IT": "Messaggio", "EN": "Message"}
        useFeedbackStore().open(titles, messages);
    });

    socket.on("eventStatus", (status) => {
        useEventStore().setStatus(status);
    });

    socket.on("subtitles", (channelId, utterance) => {
        useSubtitlesStore().setSubtitles(channelId, utterance);
    });

    socket.on("pollsGreenBg", (enabled) => {
        usePollResultsStore().setGreenBg(enabled);
    });
}
