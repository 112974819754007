import axios from "./axiosInstance";

function get() {
    return axios.get("/admin/events");
}

function suggestSlug(slug) {
    return axios.get(`/admin/events/suggest-slug?src=${slug}`);
}

function create(slug, defaultLanguage) {
    return axios.post("/admin/events", { slug, defaultLanguage });
}

function edit(slug, data) {
    return axios.patch(`/admin/events/${slug}`, data);
}

function deleteEvent(slug) {
    return axios.delete(`/admin/events/${slug}`);
}

function getLanguages(slug) {
    return axios.get(`/admin/events/${slug}/languages`);
}

function getChannels(slug) {
    return axios.get(`/admin/events/${slug}/channels`);
}

function addChannel(slug, data) {
    return axios.post(`/admin/events/${slug}/channels`, data);
}

function editChannel(slug, channelId, data) {
    return axios.put(`/admin/events/${slug}/channels/${channelId}`, data);
}

function deleteChannel(slug, channelId) {
    return axios.delete(`/admin/events/${slug}/channels/${channelId}`);
}

function addLanguage(slug, language) {
    return axios.post(`/admin/events/${slug}/languages`, { language });
}

function setDefaultLanguage(slug, language) {
    return axios.patch(`/admin/events/${slug}/languages/default`, { language });
}

function getEventInfo(slug) {
    return axios.get(`/events/${slug}`);
}

function getAdminEventInfo(slug) {
    return axios.get(`/admin/events/${slug}`);
}

function getPolls(slug) {
    return axios.get(`/events/${slug}/polls`);
}

function sendPoll(slug, pollId) {
    return axios.post(`/events/${slug}/sendPoll`, { pollId });
}

function retractPoll(slug, pollId) {
    return axios.post(`/events/${slug}/retractPoll`, { pollId });
}

function resetPoll(slug, pollId) {
    return axios.post(`/events/${slug}/resetPoll`, { pollId });
}

function showPollResults(slug, pollId) {
    return axios.post(`/events/${slug}/showPollResults`, { pollId });
}

function retractPollResults(slug, pollId) {
    return axios.post(`/events/${slug}/retractPollResults`, { pollId });
}

function setStatus(slug, status) {
    return axios.patch(`/admin/events/${slug}/status`, { status });
}

function setLightShow(slug, value) {
    return axios.post(`/admin/events/${slug}/show`, { show: value });
}

function getSeats(slug) {
    return axios.get(`/admin/events/${slug}/show/seats`);
}

export default {
    get,
    suggestSlug,
    create,
    edit,
    deleteEvent,
    getLanguages,
    addLanguage,
    getChannels,
    addChannel,
    editChannel,
    deleteChannel,
    getEventInfo,
    getAdminEventInfo,
    getPolls,
    sendPoll,
    retractPoll,
    resetPoll,
    showPollResults,
    retractPollResults,
    setStatus,
    setDefaultLanguage,
    setLightShow,
    getSeats,
};
