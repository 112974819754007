import { initializeApp } from "firebase/app";
import {
    applyActionCode,
    checkActionCode,
    confirmPasswordReset,
    getAuth,
    onAuthStateChanged,
    signInWithEmailAndPassword,
    verifyPasswordResetCode,
} from "firebase/auth";
import { useAuthStore } from "@/stores/admin/auth";

const firebaseApp = initializeApp({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
});

const auth = getAuth(firebaseApp);

onAuthStateChanged(auth, (user) => {
    if (user) useAuthStore().setUserCredential(user);
    else useAuthStore().setUserCredential(null);
});

export async function login(email, password) {
    return await signInWithEmailAndPassword(auth, email, password);
}

export async function verifyResetPasswordCode(code) {
    return await verifyPasswordResetCode(auth, code);
}

export async function confirmPassword(code, newPassword) {
    return await confirmPasswordReset(auth, code, newPassword);
}

export async function applyAction(code) {
    return await applyActionCode(auth, code);
}

export async function checkAction(code) {
    return await checkActionCode(auth, code);
}

export async function logout() {
    return await auth.signOut();
}

export async function getIdToken() {
    if (!auth.currentUser) return null;
    return await auth.currentUser.getIdToken(true);
}
