<script setup>
import { useCurrentStore } from "@/stores/current";
import { computed } from "vue";

const absolute = computed(() => {
    return useCurrentStore().getNavAbsolute;
});

defineProps({
    event: {
        type: Object,
        required: true,
    },
});

const loadUrl = import.meta.env.VITE_S3_BASE_URL;
</script>

<template>
    <div :class="{ absolute }" class="w-full box-border max-w-xl">
        <div class="bg-neutral-100 rounded-2xl h-20 mx-3 my-3 max-h-20 p-2">
            <div class="flex items-center w-full h-full relative">
                <img
                    id="navLogo"
                    :alt="`${event.slug} logo`"
                    :src="loadUrl + event.navbarLogo"
                    class="mx-auto max-h-16"
                />
            </div>
        </div>
    </div>
</template>

<style scoped></style>
