import axios from "./axiosInstance";

export function signup(data) {
    return axios.post(`/admin/auth/signup`, data);
}

export function resendVerificationEmail(email) {
    return axios.post(`/admin/auth/resend-verification-email`, { email });
}

export function resetPassword(email) {
    return axios.post(`/admin/auth/reset-password`, { email });
}

export function sendActivationEmail(email) {
    return axios.post(`/admin/auth/verify-email`, { email });
}
