<script setup>
import { onMounted } from "vue";
import { refreshDarkMode } from "@utils/darkmode";

onMounted(() => {
    refreshDarkMode();
});
</script>

<template>
    <router-view />
</template>
