import * as Sentry from "@sentry/vue";

const tracesSR = parseFloat(
    import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? 0.5
);
const environment = import.meta.env.VITE_SENTRY_ENV ?? import.meta.env.MODE;
const replaysSessionSR = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE ?? 0.5
);
const replaysErrorSR = parseFloat(
    import.meta.env.VITE_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE ?? 1.0
);

function init(app, router) {
    Sentry.init({
        app,
        dsn: "https://a15923c79dd14a4fbfe90bca393209ea@sentry.suonovivo.com/8",
        integrations: [
            Sentry.browserTracingIntegration({
                router,
                tracingOrigins: [
                    "localhost",
                    import.meta.env.VITE_API_URL,
                    /^\//,
                ],
            }),
            Sentry.replayIntegration({
                maskAllText: false,
                networkDetailAllowUrls: [/^https:\/\/api\.soonia\.app/],
            }),
        ],
        logErrors: import.meta.env.SENTRY_LOG_ERRORS ?? false,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: tracesSR,
        environment: environment,
        replaysSessionSampleRate: replaysSessionSR,
        replaysOnErrorSampleRate: replaysErrorSR,
        attachStacktrace: true,
    });
}

function setEvent(slug) {
    Sentry.setTag("event", slug);
}

function setSeat(seat) {
    Sentry.setTag("seat", seat);
}

export default {
    init,
    setEvent,
    setSeat,
    setUser: Sentry.setUser,
    captureException: Sentry.captureException,
};
