import { defineStore } from "pinia";
import events from "../services/events";
import { usePollStore } from "./poll";
import { useLightsStore } from "./lights";
import sentry from "@/services/sentry";
import { useWebRTCStore } from "@/stores/webrtc";
import { useSocketStore } from "@/stores/socketio";

export const useEventStore = defineStore("event", {
    state: () => ({ eventInfo: {}, slug: "", status: "open" }),
    actions: {
        async getEventInfo(slug) {
            const r = await events.getEventInfo(slug);
            this.eventInfo = r.data;
            usePollStore().setPoll(r.data.activePoll);
            if (r.data.status) this.setStatus(r.data.status); // "closed" or "open"
            useLightsStore().setShow(r.data.show);
            const randomColor = r.data.sooniaLightsRandomColor;
            if (randomColor) {
                useLightsStore().setRandomColor(randomColor);
            }
        },
        setSlug(slug) {
            if (this.slug === slug) return;
            this.slug = slug;
            sentry.setEvent(slug);
            useSocketStore().setEvent(slug);
        },
        setStatus(status) {
            this.status = status;
            if (status === "closed" && useWebRTCStore().isConnected) {
                useWebRTCStore().stopStream();
            }
        },
    },
    getters: {
        getSlug: (state) => state.slug,
        getEvent: (state) => {
            const e = state.eventInfo;
            if (!e || Object.keys(e).length === 0) return null;
            const languages = [e.defaultLanguage];
            const channels = e.channels || [];
            for (const channel of channels)
                if (!languages.includes(channel.language))
                    languages.push(channel.language);
            e.languages = languages;
            return e;
        },
        hasAudioChannels: (state) => {
            const e = state.eventInfo;
            if (!e || !e.channels) return false;
            return e.channels.length > 0;
        },
        isClosed: (state) => state.status === "closed",
    },
});
