import { createChatWoot } from "@productdevbook/chatwoot/vue";

const adminPrefix = import.meta.env.VITE_ADMIN_PREFIX || "admin";
const chatwoot = createChatWoot({
    init: {
        baseUrl: import.meta.env.VITE_CHATWOOT_BASE_URL,
        websiteToken: import.meta.env.VITE_CHATWOOT_WEBSITE_TOKEN,
    },
    settings: {
        locale: "en",
        position: "right",
        launcherTitle: "Need help?",
        hideMessageBubble: window.location.host.split(".")[0] !== adminPrefix,
    },
});

export { chatwoot };
