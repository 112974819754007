import { defineStore } from "pinia";
import { ref } from "vue";
import { useWebRTCStore } from "./webrtc";
import { useLightsStore } from "./lights";
import NoSleep from "nosleep.js";
import { useEventStore } from "./event";
import { useFeedbackStore } from "@/stores/feedback";
import { useSocketStore } from "@/stores/socketio";
import { useSubtitlesStore } from "@/stores/subtitles";

const noSleep = new NoSleep();
const screensaverTimeout = import.meta.env.VITE_SCREENSAVER_TIMEOUT ?? 5000;
const screensaverTimer = ref();
const screensaverTimerFinished = ref(false);

export const useCurrentStore = defineStore("current", {
    state: () => ({
        popupOpen: false,
        footer: true,
        channel: null,
        browserName: navigator.appName,
        navAbsolute: false,
        firstInteraction: false,
        admin: false,
        pollResults: false, // poll results routes
    }),
    actions: {
        async setPopupOpen(value) {
            this.popupOpen = value;
        },
        async setFooter(value) {
            this.footer = value;
        },
        setChannel(value) {
            this.channel = value;
            useSocketStore().setLanguage(value?.language);
            useSubtitlesStore().reset();
        },
        setFirstInteraction(value) {
            this.firstInteraction = value;
        },
        setAdmin(value) {
            this.admin = value;
        },
        setPollResults(value) {
            this.pollResults = value;
        },
        async restartScreensaverTimer() {
            screensaverTimerFinished.value = false;
            if (screensaverTimer.value) clearTimeout(screensaverTimer.value);
            screensaverTimer.value = setTimeout(
                () => (screensaverTimerFinished.value = true),
                screensaverTimeout
            );
        },
        getCurrentOS() {
            let nAgt = navigator.userAgent;
            let fullVersion = "" + parseFloat(navigator.appVersion);
            let majorVersion = parseInt(navigator.appVersion, 10);
            let nameOffset, verOffset, ix;

            // In Opera, the true version is after "Opera" or after "Version"
            if ((verOffset = nAgt.indexOf("Opera")) !== -1) {
                this.browserName.value = "Opera";
                fullVersion = nAgt.substring(verOffset + 6);
                if ((verOffset = nAgt.indexOf("Version")) !== -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In MSIE, the true version is after "MSIE" in userAgent
            else if ((verOffset = nAgt.indexOf("MSIE")) !== -1) {
                this.browserName.value = "Microsoft Internet Explorer";
                fullVersion = nAgt.substring(verOffset + 5);
            }
            // In Chrome, the true version is after "Chrome"
            else if ((verOffset = nAgt.indexOf("Chrome")) !== -1) {
                this.browserName.value = "Chrome";
                fullVersion = nAgt.substring(verOffset + 7);
            }
            // In Safari, the true version is after "Safari" or after "Version"
            else if ((verOffset = nAgt.indexOf("Safari")) !== -1) {
                this.browserName.value = "Safari";
                fullVersion = nAgt.substring(verOffset + 7);
                if ((verOffset = nAgt.indexOf("Version")) !== -1)
                    fullVersion = nAgt.substring(verOffset + 8);
            }
            // In Firefox, the true version is after "Firefox"
            else if ((verOffset = nAgt.indexOf("Firefox")) !== -1) {
                this.browserName.value = "Firefox";
                fullVersion = nAgt.substring(verOffset + 8);
            }
            // In most other browsers, "name/version" is at the end of userAgent
            else if (
                (nameOffset = nAgt.lastIndexOf(" ") + 1) <
                (verOffset = nAgt.lastIndexOf("/"))
            ) {
                this.browserName.value = nAgt.substring(nameOffset, verOffset);
                fullVersion = nAgt.substring(verOffset + 1);
                if (
                    this.browserName.value.toLowerCase() ===
                    this.browserName.value.toUpperCase()
                ) {
                    this.browserName.value = navigator.appName;
                }
            }
            // trim the fullVersion string at semicolon/space if present
            if ((ix = fullVersion.indexOf(";")) !== -1)
                fullVersion = fullVersion.substring(0, ix);
            if ((ix = fullVersion.indexOf(" ")) !== -1)
                fullVersion = fullVersion.substring(0, ix);

            majorVersion = parseInt("" + fullVersion, 10);
            if (isNaN(majorVersion)) {
                fullVersion = "" + parseFloat(navigator.appVersion);
                majorVersion = parseInt(navigator.appVersion, 10);
            }
        },
        async enableNoSleep() {
            if (!noSleep.isEnabled) await noSleep.enable();
        },
        async disableNoSleep() {
            if (noSleep.isEnabled) await noSleep.disable();
        },
        async refreshNoSleep() {
            if (this.channel) {
                if (this.standbyDuringListening) await this.enableNoSleep();
                else await this.disableNoSleep();
            } else await this.enableNoSleep();
        },
    },
    getters: {
        footerGray: (state) =>
            !state.getIsPollResults &&
            !useWebRTCStore().isConnected &&
            useEventStore().hasAudioChannels,
        showScreensaver: (state) => {
            if (state.admin) return false; // if admin, never show screensaver
            if (state.pollResults) return false; // if poll results, never show screensaver
            if (useEventStore().isClosed) return true; // if closed, always show screensaver
            if (useEventStore().getEvent?.channels?.length === 0) return true; // if no languages, always show screensaver
            if (useSubtitlesStore().getReceived) return false; // if subtitles, never show screensaver
            if (useFeedbackStore().feedback) return false; // if feedback, never show screensaver
            if (state.popupOpen) return false; // if popup open, never show screensaver
            if (useLightsStore().getShow) return true; // if lights on, always show screensaver (with lights)
            if (screensaverTimerFinished.value) return true; // if timer finished, always show screensaver
            return false;
        },
        standbyDuringListening: (state) =>
            state.browserName.toLowerCase() !== "safari",
        noSleepEnabled: noSleep.isEnabled,
        getFirstInteraction: (state) => state.firstInteraction,
        showTapToStart: (state) => {
            const event = useEventStore().eventInfo;
            if (!event) return false;
            if (event.tapToStart === "always")
                return state.firstInteraction === false;
            if (event.tapToStart === "never" || !event.tapToStart) return false;
            if (event.tapToStart === "show") {
                return (
                    state.firstInteraction === false &&
                    useLightsStore().show === true
                );
            }
        },
        language: (state) => {
            return state.channel?.language;
        },
        getNavAbsolute: (state) => state.navAbsolute,
        getIsAdmin: (state) => state.admin,
        getIsPollResults: (state) => state.pollResults,
        getChannel: (state) => state.channel,
    },
});
