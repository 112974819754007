import { ref } from "vue";
import { defineStore } from "pinia";
import sentry from "../services/sentry";
import { useSocketStore } from "@/stores/socketio";

export const useSeatStore = defineStore("seat", () => {
    const seatRow = ref("");
    const seatNumber = ref("");

    function setSeatRow(row) {
        seatRow.value = row;
    }

    function setSeatNumber(number, confirm = false) {
        seatNumber.value = number;
        if (confirm) this.confirm();
    }

    function confirm() {
        sentry.setSeat((seatRow.value + seatNumber.value).toUpperCase());
        useSocketStore().setSeat(
            (seatRow.value + seatNumber.value).toUpperCase()
        );
    }

    return { seatNumber, seatRow, setSeatRow, setSeatNumber, confirm };
});
