import axios from "../axiosInstance";

function fetchProfile() {
    return axios.get(`/admin/profile`);
}

function fetchLicenses() {
    return axios.get(`/admin/profile/licenses`);
}

function createLicense(data) {
    return axios.post(`/admin/profile/licenses`, data);
}

function updateLicense(licenseId, eventSlug) {
    return axios.patch(`/admin/profile/licenses/${licenseId}`, {
        eventSlug,
    });
}

function fetchAudioDevices() {
    return axios.get(`/admin/profile/audio-devices`);
}

export default {
    fetchProfile,
    fetchLicenses,
    updateLicense,
    fetchAudioDevices,
    createLicense,
};
