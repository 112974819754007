import { defineStore } from "pinia";
import { useCurrentStore } from "@/stores/current";

export const useSubtitlesStore = defineStore("subtitles", {
    state: () => ({
        activeChannels: [],
        partialSubtitles: "",
        finalSubtitles: [],
        received: false,
    }),
    actions: {
        reset() {
            this.partialSubtitles = "";
            this.finalSubtitles = [];
            this.received = false;
        },
        setSubtitles(channelId, subtitles) {
            if (!this.activeChannels.includes(channelId))
                this.activeChannels.push(channelId);
            if (useCurrentStore().getChannel?.id !== channelId) return;
            if (!subtitles) return;
            if (subtitles.type === "partial") this.partialSubtitles = subtitles;
            else if (subtitles.type === "final") {
                this.finalSubtitles.push(subtitles);
                this.partialSubtitles = "";
            }
            if (subtitles.transcription) this.received = true;
        },
    },
    getters: {
        getReceived: (state) => state.received,
        getTranscription: (state) => {
            // get last 5 final subtitles
            const finalSubtitles = state.finalSubtitles.slice(-5);
            let transcription = "";
            for (const subtitle of finalSubtitles) {
                transcription += subtitle.transcription + "\n";
            }
            if (state.partialSubtitles) {
                transcription += state.partialSubtitles.transcription;
            }
            return transcription.trim();
        },
        getPartialSubtitles: (state) =>
            state.partialSubtitles?.transcription || "",
        getFinalSubtitles: (state) =>
            state.finalSubtitles.map((s) => s.transcription),
        getChannelIsActive: (state) => (channelId) =>
            state.activeChannels.includes(channelId),
    },
});
