import axios from "axios";
import { useAuthStore } from "@/stores/admin/auth";

const instance = axios.create({
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
    baseURL: import.meta.env.VITE_API_URL,
});

instance.interceptors.request.use(async (config) => {
    if (!useAuthStore().getUser) return config;
    const token = await useAuthStore().getUserIdToken();
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

instance.interceptors.response.use(
    (response) => response,
    (error) => {
        if (error.response?.status === 401) {
            useAuthStore().logout();
        }
        return Promise.reject(error);
    }
);

export default instance;
