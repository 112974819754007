import { defineStore } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import profile from "@/services/admin/profile";

export const useProfileStore = defineStore("admin-profile", {
    state: () => ({ profile: null, licenses: [], audioDevices: [] }),
    actions: {
        async fetchProfile() {
            useLoadingStore().startLoading();
            try {
                const r = await profile.fetchProfile();
                this.profile = r.data;
            } catch (error) {
                this.error = error;
            }
            useLoadingStore().stopLoading();
        },
        async fetchLicenses() {
            useLoadingStore().startLoading();
            try {
                const r = await profile.fetchLicenses();
                this.licenses = r.data;
            } catch (error) {
                this.error = error;
            }
            useLoadingStore().stopLoading();
        },
        async updateLicense(licenseId, eventSlug) {
            useLoadingStore().startLoading();
            try {
                const r = await profile.updateLicense(licenseId, eventSlug);
                this.licenses = this.licenses.map((license) => {
                    if (license.id === licenseId) {
                        return r.data;
                    }
                    return license;
                });
            } catch (error) {
                this.error = error;
            }
            useLoadingStore().stopLoading();
        },
        async createLicense(data) {
            return new Promise((resolve, reject) => {
                profile
                    .createLicense(data)
                    .then((r) => {
                        resolve(r.data);
                    })
                    .catch((error) => {
                        reject(error);
                    });
            });
        },
        async fetchAudioDevices(withLoading = true) {
            if (withLoading) useLoadingStore().startLoading();
            try {
                const r = await profile.fetchAudioDevices();
                this.audioDevices = r.data;
            } catch (error) {
                this.error = error;
            }
            if (withLoading) useLoadingStore().stopLoading();
        },
    },
    getters: {
        getProfile: (state) => state.profile,
        getPreferredLanguage: (state) => state.profile?.languagePreference,
        getLicenses: (state) => state.licenses,
        getAudioDevices: (state) => state.audioDevices,
        getFullName: (state) => {
            if (!state.profile) return "";
            return `${state.profile.firstName} ${state.profile.lastName}`;
        },
        getSuperAdmin: (state) => !!state.profile?.superAdmin,
    },
});
