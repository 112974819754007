import { defineStore } from "pinia";
import { useLoadingStore } from "@/stores/loading";
import {
    applyAction,
    checkAction,
    confirmPassword,
    getIdToken,
    login,
    logout,
    verifyResetPasswordCode,
} from "@/services/firebase";
import {
    resendVerificationEmail,
    resetPassword,
    sendActivationEmail,
    signup,
} from "@/services/auth";
import { useNotificationsStore } from "@/stores/notifications";
import { convertError } from "@utils/generic";
import sentry from "@/services/sentry";
import { useProfileStore } from "@/stores/admin/profile";
import { useI18nStore } from "@/stores/i18n";
import { useChatWoot } from "@productdevbook/chatwoot/vue";

function convertFirebaseErrorToCode(error) {
    const code = error?.code;
    if (!code) return "unknown";
    if (!code.startsWith("auth/")) return "unknown";
    //  invalid-login-credentials -> invalidLoginCredentials
    return convertError(code.replace("auth/", ""));
}

export const useAuthStore = defineStore("auth", {
    state: () => ({ userCredential: null }),
    actions: {
        async register(data) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await signup(data);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(
                        error?.response?.data
                    )}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async login(username, password) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                this.setUserCredential(await login(username, password));
                await useProfileStore().fetchProfile();
                useI18nStore().setLocale(
                    useProfileStore().getPreferredLanguage
                );
                useChatWoot().setLocale(useProfileStore().getPreferredLanguage);
                useChatWoot().setUser(useProfileStore().getProfile.id, {
                    email: useProfileStore().getProfile.email,
                    identifier_hash:
                        useProfileStore().getProfile.identifierHash,
                    name: useProfileStore().getFullName,
                });
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async getUserIdToken() {
            return await getIdToken(true);
        },
        async resendVerificationEmail(email) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await resendVerificationEmail(email);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(
                        error?.response?.data
                    )}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async sendActivationEmail(email) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await sendActivationEmail(email);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(
                        error?.response?.data
                    )}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async resetPassword(email) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await resetPassword(email);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async verifyResetPasswordCode(code) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await verifyResetPasswordCode(code);
                success = true;
            } catch (error) {
                if (error?.code === "auth/invalid-action-code")
                    error.code = "auth/password-reset-invalid-code";
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async confirmPassword(code, newPassword) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await confirmPassword(code, newPassword);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async checkActionCode(code) {
            useLoadingStore().startLoading();
            let success = null;
            try {
                success = await checkAction(code);
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async applyActionCode(code) {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await applyAction(code);
                success = true;
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        async logout() {
            useLoadingStore().startLoading();
            let success = false;
            try {
                await logout();
                this.setUserCredential(null);
                success = true;
                sentry.setUser(null);
                useChatWoot().reset();
            } catch (error) {
                useNotificationsStore().error(
                    `errors.auth.${convertFirebaseErrorToCode(error)}`
                );
            }
            useLoadingStore().stopLoading();
            return success;
        },
        setUserCredential(userCredential) {
            this.userCredential = userCredential;
            this.setSentryUser(userCredential?.user || userCredential);
        },
        setSentryUser(user) {
            if (!user) return sentry.setUser(null);
            return sentry.setUser({
                id: user.uid,
                email: user.email,
            });
        },
    },
    getters: {
        getUser: (state) => state.userCredential?.user || state.userCredential,
        isAuthenticated: (state) => !!state.userCredential,
    },
});
