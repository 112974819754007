import axios from "../axiosInstance";

function getPolls(slug) {
    return axios.get(`/admin/events/${slug}/polls`);
}

function addPoll(slug, poll) {
    return axios.post(`/admin/events/${slug}/polls`, poll);
}

function editPoll(slug, pollId, poll) {
    return axios.put(`/admin/events/${slug}/polls/${pollId}`, poll);
}

function deletePoll(slug, pollId) {
    return axios.delete(`/admin/events/${slug}/polls/${pollId}`);
}

function resetPoll(slug, pollId) {
    return axios.post(`/admin/events/${slug}/polls/${pollId}/reset`);
}

function setPollStatus(slug, pollId, status) {
    return axios.post(`/admin/events/${slug}/polls/${pollId}/status`, {
        status,
    });
}

function setPollResultsStatus(slug, pollId, status) {
    return axios.post(`/admin/events/${slug}/polls/${pollId}/results`, {
        showResults: status,
    });
}

function getGreenBg(slug) {
    return axios.get(`/admin/events/${slug}/polls/green-bg`);
}

function setGreenBg(slug, enabled) {
    return axios.post(`/admin/events/${slug}/polls/green-bg`, {
        enabled,
    });
}

function exportPolls(slug) {
    return axios({
        url: `/admin/events/${slug}/polls/export`,
        method: "GET",
        responseType: "blob",
    });
}

export default {
    getPolls,
    addPoll,
    editPoll,
    deletePoll,
    resetPoll,
    setPollStatus,
    setPollResultsStatus,
    getGreenBg,
    setGreenBg,
    exportPolls,
};
