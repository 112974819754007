<script setup>
import SooniaSpinner from "@cmp/SooniaSpinner.vue";
import { computed } from "vue";

const eventsWCS = import.meta.env.VITE_EVENTS_WITH_CUSTOM_SPLASH ?? "";
const eventsWithCustomSplash = eventsWCS.split(",");
const loadUrl = import.meta.env.VITE_S3_BASE_URL;

const props = defineProps({
    event: {
        type: Object,
        required: true,
    },
});

const customSplash = computed(() =>
    eventsWithCustomSplash.includes(props.event?.slug)
);
const customSplashUrl = computed(() => {
    if (!customSplash.value || !props.event?.splash) return "";
    return loadUrl + props.event.splash;
});
</script>

<template>
    <div
        class="h-full flex flex-col justify-center items-center z-[100] absolute inset-0 bg-white"
    >
        <SooniaSpinner v-if="!customSplash" class="w-1/2" />
        <img
            v-else
            :src="customSplashUrl"
            class="w-1/2 max-h-full"
            :alt="`Splash for ${event.slug}`"
        />
    </div>
</template>
