import { useCurrentStore } from "@/stores/current";

function refreshDarkMode() {
    // Don't use dark mode if not admin
    if (!useCurrentStore().admin) {
        document.documentElement.classList.remove("dark");
        return;
    }

    // On page load or when changing themes, best to add inline in `head` to avoid FOUC
    if (
        localStorage.theme === "dark" ||
        (!("theme" in localStorage) &&
            window.matchMedia("(prefers-color-scheme: dark)").matches)
    ) {
        document.documentElement.classList.add("dark");
    } else {
        document.documentElement.classList.remove("dark");
    }
}

function setDarkMode(darkMode) {
    if (darkMode) localStorage.theme = darkMode;
    else localStorage.removeItem("theme");
    refreshDarkMode();
}

function getDarkMode() {
    return document.documentElement.classList.contains("dark");
}

window.setDarkMode = setDarkMode;

export { refreshDarkMode, setDarkMode, getDarkMode };
